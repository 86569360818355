import React from 'react';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './style.css';
import MainTemplateHero from "../../components/hero/main-template";
import ReactMarkdown from 'react-markdown';
import ObfuscatedEmail from "../../email/obfuscated-email";
import CopyableObfuscatedEmail from "../../email/copyable-email";
import {determineAvailabilityState} from "../../util/availabilityUtils";
import {useProjectConfig} from "../../hooks/useprojectConfig";

const Contact = () => {
    const { t, i18n } = useTranslation();
    const { endDateOfCurrentProject } = useProjectConfig();

    // Use the utility function
    const availabilityState = determineAvailabilityState(endDateOfCurrentProject, i18n);

    // Map the availability state to a CSS class for styling
    const getAvailabilityCardClass = () => {
        if (availabilityState === 'open') {
            return 'state-open';
        } else if (availabilityState.type === 'startingFrom') {
            return 'state-starting';
        } else {
            return 'state-consultation';
        }
    };

    // Get the availability text
    const getAvailabilityText = () => {
        if (availabilityState === 'open') {
            return t('commons.availability.details.openForProjects');
        } else if (availabilityState.type === 'startingFrom') {
            return t('commons.availability.details.openForProjectsStarting', {
                month: availabilityState.month,
                year: availabilityState.year
            });
        } else {
            return t('commons.availability.details.consultationOnly', {
                month: availabilityState.month,
                year: availabilityState.year
            });
        }
    };

    return (
        <div>
            <MainTemplateHero title={t("contact.hero.title")}
                              imageId="phone-image"
                              tagline={<ReactMarkdown>{t("contact.hero.tagline")}</ReactMarkdown>}
                              lottieAnimation="/assets/bubbles-v2.lottie"
                              backgroundColor="#E8F1F9"
                              textColor="#222222"
            />
            <div className="main-container">
                <h2>{t('contact.title')}</h2>
                <p>{t('contact.introduction')}</p>

                <div id="send-email-btn" className="pt-3 d-flex justify-content-center">
                    <ObfuscatedEmail
                        email="contact@elouarti.net"
                        useMailto={true}
                        className="std-btn"
                    >
                        {t('contact.emailButton')}
                    </ObfuscatedEmail>
                </div>

                <div className="content-section-inter-spacer contact-details row gy-4">
                    <div className="col-md-12">
                        <div className={`availability-card ${getAvailabilityCardClass()} d-flex`}>
                            <div className="me-3">
                                <i className="bi bi-calendar-event icon-primary"></i>
                            </div>
                            <div>
                                <h3>Verfügbarkeit</h3>
                                <p className="m-0">{getAvailabilityText()}</p>
                            </div>
                        </div>

                        <div className="px-4 mb-4 d-flex">
                            <div className="me-3">
                                <i className="bi bi-envelope icon-primary"></i>
                            </div>
                            <div>
                                <h3>{t('contact.contactDetails.email.title')}</h3>
                                <CopyableObfuscatedEmail
                                    email="contact@elouarti.net"
                                    copySuccessText={t('contact.contactDetails.email.emailCopied', 'Email copied!')}
                                />
                            </div>
                        </div>

                        <div className="px-4 mb-4 d-flex">
                            <div className="me-3">
                                <i className="bi bi-clock icon-primary"></i>
                            </div>
                            <div>
                                <h3>{t('contact.contactDetails.processingTime.title')}</h3>
                                <p>{t('contact.contactDetails.processingTime.description')}</p>
                            </div>
                        </div>

                        <div className="px-4 d-flex">
                            <div className="me-3">
                                <i className="bi bi-telephone icon-primary"></i>
                            </div>
                            <div>
                                <h3>{t('contact.contactDetails.phone.title')}</h3>
                                <p>{t('contact.contactDetails.phone.description')}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <h3 className="content-section-spacer">{t('contact.expertise.title')}</h3>
                <p>{t('contact.expertise.description1')}</p>
                <p>{t('contact.expertise.description2')}</p>

                <div className="mt-4 d-flex flex-column link-container">
                    <a href="/services" className="link-primary d-inline-flex align-items-center mb-3 mb-sm-2">
                        <i className="bi bi-arrow-right me-2"></i>
                        <span>{t('contact.links.services')}</span>
                    </a>
                    <a href="/expertise" className="link-primary d-inline-flex align-items-center mb-3 mb-sm-3">
                        <i className="bi bi-arrow-right me-2"></i>
                        <span>{t('contact.links.expertise')}</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Contact;